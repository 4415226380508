<template>
	<div>
         <myheader></myheader>
         <div class="usermain">
             <div class="main">
                 <div class="text" v-html="content">
		
		    </div>
             </div>
             
         </div>
		
		
        <myfooter></myfooter>
		<myright></myright>
	</div>
</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'
import {aboutAPI} from "@/api/footnav"
export default {
    components: {
    myheader, myfooter, myright
  },
	    data () {
	      return {
	        content:"",
            name:"",
            i:1
	      }
	    },
		created(){
			this.getabout()
		},
		methods:{
			getabout(){
				aboutAPI().then(res=>{
					this.content=res.cateList[this.i].content
					this.name=res.cateList[this.i].name
				})
			}
		}
	  }
</script>

<style lang="scss" scope>
.usermain{
	background-color: #f9f9f9;
	padding-bottom: 20px;
	padding-top: 10px;
	.main{
		width: 1200px;
		margin:0px auto;
		background-color: #fff;
        min-height: 500px;
        padding: 15px;
	}

}
</style>

